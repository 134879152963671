import * as types from 'types'
import {
	storeItemsTransactor,
	storeItemsMerger,
	entityInterceptEnqueue,
	deepMerge,
	ArrayMerger,
} from 'reducers/z_redux_helpers'

const FALSY_LOADERS = {
	create: false,
	read: false,
	update: false,
	delete: false,
}

const INITIAL_STATE = {
	studies: [],
	programs: [],
	data: [],
	current: {},
	lastFetched: null,
	loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
}

const patientDecorator = (entity, patientEntitiesMap) => {
	return patientEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: true,
		}
		return entityMap
	})
}

const patientUnsyncedDecorator = (entity, patientEntitiesMap) => {
	return patientEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: false,
		}
		return entityMap
	})
}

export default (state = JSON.parse(JSON.stringify(INITIAL_STATE)), action) => {
	switch (action.type) {
		case types.PURGE: {
			return JSON.parse(JSON.stringify(INITIAL_STATE))
		}

		// GET PROGRAMS
		case types.GET_PROGRAMS_SUCCESS: {
			let newPayload = ArrayMerger(state.data, [...action.payload.programs, ...state.data])
			return {
				...state,
				programs: newPayload,
				lastFetched: new Date().getTime(),
				loading: {
					...state.loading,
					read: false,
				},
			}
		}

		case types.GET_PROGRAMS_FAIL: {
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
				},
			}
		}

		case types.GET_PROGRAMS: {
			return {
				...state,
				loading: {
					...state.loading,
					read: true,
				},
			}
		}

		// GET PATIENTS
		case types.GET_PATIENTS_SUCCESS: {
			const newPayload = ArrayMerger(state.data, [...action.payload.patients, ...state.data])
			return {
				...state,
				data: newPayload,
				lastFetched: new Date().getTime(),
				loading: {
					...state.loading,
					read: false,
				},
			}
		}

		case types.GET_PATIENTS_FAIL: {
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
				},
			}
		}

		case types.GET_PATIENTS: {
			return {
				...state,
				loading: {
					...state.loading,
					read: true,
				},
			}
		}

		case types.GET_STUDIES_SUCCESS: {
			return {
				...state,
				studies: action.payload.experiments,
				programs: action.payload.programs,
				loading: {
					...state.loading,
					read: false,
				},
			}
		}

		case types.GET_STUDIES_FAIL: {
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
				},
			}
		}

		case types.GET_STUDIES: {
			return {
				...state,
				loading: {
					...state.loading,
					read: true,
				},
			}
		}

		case types.GET_USER_PROGRAMS: {
			return {
				...state,
				loading: {
					...state.loading,
					read: true,
				},
			}
		}

		case types.GET_USER_PROGRAMS_SUCCESS: {
			return {
				...state,
				programs: action.payload.programs,
				loading: {
					...state.loading,
					read: false,
				},
			}
		}

		case types.GET_USER_PROGRAMS_FAIL: {
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
				},
			}
		}

		// UPDATE PATIENT
		case types.UPDATE_PATIENT_SUCCESS: {
			return {
				...state,
				current: state.current?.key ? { ...state.current, ...action.payload } : state.current,
				data: state.current?.key
					? storeItemsMerger(
							state.data,
							storeItemsTransactor(
								state,
								'data',
								action.payload,
								{
									id: action.payload.id,
								},
								patientDecorator
							)
					  )
					: state.data,
				loading: {
					...state.loading,
					update: false,
				},
			}
		}

		case types.UPDATE_PATIENT_FAIL: {
			return {
				...state,

				loading: {
					...state.loading,
					update: false,
				},
			}
		}
		case types.UPDATE_PATIENT: {
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.id),
				loading: {
					...state.loading,
					update: true,
				},
			}
		}

		// DELETE PATIENT
		case types.DELETE_PATIENT_SUCCESS: {
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.id),
				loading: {
					...state.loading,
					delete: false,
				},
			}
		}
		case types.DELETE_PATIENT_FAIL: {
			return {
				...state,
				loading: {
					...state.loading,
					delete: false,
				},
			}
		}

		case types.DELETE_PATIENT: {
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.id),
				loading: {
					...state.loading,
					delete: true,
				},
			}
		}

		// ADD PATIENT
		case types.ADD_PATIENT_SUCCESS: {
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						patientDecorator
					)
				),
				loading: {
					...state.loading,
					create: false,
				},
			}
		}

		case types.ADD_PATIENT_FAIL: {
			return {
				...state,
				loading: {
					...state.loading,
					create: false,
				},
			}
		}

		case types.ADD_PATIENT: {
			return {
				...state,
				loading: {
					...state.loading,
					create: true,
				},
			}
		}

		// CURRENT PATIENT
		case types.CURRENT_PATIENT_SET: {
		}
		case types.CURRENT_PATIENT_CLEAR: {
			return { ...state, current: action.payload }
		}

		case types.RESET_PATIENT_LOADERS: {
			return {
				...state,
				loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
			}
		}

		case 'FAST_FORWARD_STORES': {
			return deepMerge(JSON.parse(JSON.stringify(INITIAL_STATE)), state)
		}

		case types.SCENE: {
			return {
				...state,
				loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
			}
		}

		// DEFAULT
		default: {
			return state
		}
	}
}
