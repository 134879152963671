import axiosInstance, { AxiosAbortController } from 'src/axiosConfig'
import { paginateDefaultArgs, paginateExhaust } from 'api/api_utils'

const formatPatientData = (patientData) => ({
	id: patientData.id,
	...patientData,
})

export const addPatient = (patientData) => {
	return axiosInstance.request({
		url: 'v1/patients',
		source: AxiosAbortController.signal,
		method: 'post',
		data: formatPatientData(patientData),
		noFederate: true,
	})
}

export const getPatientPicture = (patientData) => {
	return axiosInstance.request({
		url: `v1/users/${patientData.key}/profilePicture`,
		source: AxiosAbortController.signal,
		method: 'get',
		noFederate: true,
	})
}

export const requestPatientProfile = (patientData) => {
	return axiosInstance.request({
		method: 'get',
		source: AxiosAbortController.signal,
		url: `v1/users/${encodeURIComponent(patientData.key)}`,
	})
}

export const updatePatient = (patientData) => {
	return axiosInstance.request({
		url: `v1/patients/${patientData.id}`,
		source: AxiosAbortController.signal,
		method: 'put',
		data: formatPatientData(patientData),
		noFederate: true,
	})
}

export const removePatient = (patientData) => {
	return axiosInstance.request({
		url: `v1/patients/${patientData.id}`,
		source: AxiosAbortController.signal,
		method: 'delete',
		noFederate: true,
	})
}

export const fetchPatients = (args, reduxEvent) => {
	const queryString = paginateDefaultArgs(args)
	return paginateExhaust(
		axiosInstance.request,
		args,
		{
			url: `v1/patients?${queryString}`,
			source: AxiosAbortController.signal,
			method: 'get',
			noFederate: true,
		},
		'patients',
		1,
		[],
		reduxEvent
	)
}

export const fetchStudies = (args) => {
	const queryString = paginateDefaultArgs(args)
	return axiosInstance.request({
		url: `v1/patients-access?${queryString}`,
		source: AxiosAbortController.signal,
		method: 'get',
		noFederate: true,
	})
}

export const fetchPrograms = (args) => {
	const queryString = paginateDefaultArgs(args)
	return axiosInstance.request({
		url: `v1/patients-programs?${queryString}`,
		source: AxiosAbortController.signal,
		method: 'get',
		noFederate: true,
	})
}
