/**
 * CRUD Actions for PATIENT
 */
// ADD
export const ADD_PATIENT_SUCCESS = 'ADD_PATIENT_SUCCESS'
export const ADD_PATIENT_FAIL = 'ADD_PATIENT_FAIL'
export const ADD_PATIENT = 'ADD_PATIENT'
// UPDATE
export const UPDATE_PATIENT_SUCCESS = 'UPDATE_PATIENT_SUCCESS'
export const UPDATE_PATIENT_FAIL = 'UPDATE_PATIENT_FAIL'
export const UPDATE_PATIENT = 'UPDATE_PATIENT'
// DELETE
export const DELETE_PATIENT_SUCCESS = 'DELETE_PATIENT_SUCCESS'
export const DELETE_PATIENT_FAIL = 'DELETE_PATIENT_FAIL'
export const DELETE_PATIENT = 'DELETE_PATIENT'
// GET ALL
export const GET_PATIENTS_SUCCESS_COMPLETE = 'GET_PATIENTS_SUCCESS_COMPLETE'
export const GET_PATIENTS_SUCCESS = 'GET_PATIENTS_SUCCESS'
export const GET_PATIENTS_FAIL = 'GET_PATIENTS_FAIL'
export const GET_PATIENTS = 'GET_PATIENTS'

export const GET_STUDIES_SUCCESS = 'GET_STUDIES_SUCCESS'
export const GET_STUDIES_FAIL = 'GET_STUDIES_FAIL'
export const GET_STUDIES = 'GET_STUDIES'

/**
 * Current Types
 */
// CURRENT PATIENT
export const CURRENT_PATIENT_SET = 'CURRENT_PATIENT_SET'
export const CURRENT_PATIENT_CLEAR = 'CURRENT_PATIENT_CLEAR'

export const MEDIA_PATIENT_SET = 'MEDIA_PATIENT_SET'

export const RESET_PATIENT_LOADERS = 'RESET_PATIENT_LOADERS'
